<template>
  <div>
    <el-dialog class="dialogs" title="车辆出险登记" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
      <el-form ref="dform" :model="dform" label-width="100px" :rules="drules">
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="车辆牌号" :label-width="labelWidth" prop="auto_id">
              <el-select
                v-model="dform.auto_id"
                filterable
                remote
                reserve-keyword
                :remote-method="remoteMethod"
                clearable
                placeholder="请输入关键词"
                :loading="loading"
                @change="setpreidfun"
              >
                <el-option v-for="item in carOptions" :key="item.id" :label="item.auto_number" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="所属项目" :label-width="labelWidth">
              <el-input v-model="cform.project" disabled placeholder="无">
                <i slot="suffix" class="iconfont icon-yuan1" />
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="车辆类型" :label-width="labelWidth">
              <el-input v-model="cform.cartype" disabled placeholder="无">
                <i slot="suffix" class="iconfont icon-yuan1" />
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="历史历程" :label-width="labelWidth">
              <el-input v-model="cform.metters" disabled placeholder="无">
                <i slot="suffix" class="iconfont icon-yuan1" />
                <template slot="append">KM</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="insureTypeBox">
          <!-- @selection-change="handleSelectionChange" -->
          <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @select="handleSelectionChange">
            <el-table-column type="selection" width="55" :selectable="selected"></el-table-column>
            <el-table-column prop="type" label="出险类型" show-overflow-tooltip></el-table-column>
            <el-table-column prop="company" label="保险公司" show-overflow-tooltip></el-table-column>
            <el-table-column prop="end_date" label="到期时间" show-overflow-tooltip></el-table-column>
            <el-table-column prop="valid_status_name" label="状态" show-overflow-tooltip></el-table-column>
            <el-table-column label="金额" show-overflow-tooltip>
              <template slot-scope="scope">
                <el-input @input="scope.row.ins_money = inputCheck(scope.row.ins_money)" :disabled="scope.row.disabled" v-model="scope.row.ins_money"></el-input>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="detailBox">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="事故发生时间" :label-width="labelWidth" prop="happen_time">
                <el-date-picker
                  v-model="dform.happen_time"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm"
                  format="yyyy-MM-dd HH:mm"
                  placeholder="选择日期时间"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="事故发生地点" :label-width="labelWidth" prop="happen_addr">
                <el-input v-model="dform.happen_addr" placeholder="请输入事故地点">
                  <i slot="suffix" class="iconfont icon-yuan1" />
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="我方责任占比" :label-width="labelWidth" prop="losses_type">
                <el-select v-model="dform.losses_type" placeholder="请选择责任占比">
                  <el-option v-for="item in dutyOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="事故判定书" :label-width="labelWidth">
                <el-radio-group v-model="dform.is_response_file" @change="changeIsFile">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" v-if="dform.is_response_file === 1">
            <el-col :span="24">
              <el-form-item label="上传事故判定书" :label-width="labelWidth" prop="response_file">
                <el-upload ref="uploadJudge" :limit="1" :file-list="fileList" action="" :multiple="false" list-type="picture-card" :http-request="handleJudge">
                  <!-- <i class="el-icon-plus"></i> -->
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span class="el-upload-list__item-delete" @click="handleRemoveJudge(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 0px">
            <el-col :span="24">
              <el-form-item label="事故详情" :label-width="labelWidth" prop="reason">
                <!-- <template slot-scope="scope"> -->
                <!-- <div> -->
                <!-- <div>事故判定书</div> -->
                <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 8 }" placeholder="请输入内容" v-model="dform.reason"></el-input>
                <!-- </div> -->
                <!-- </template> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-top: 0px">
            <el-col :span="24">
              <el-form-item label="事故现场照片" :label-width="labelWidth" prop="response_pic">
                <el-upload ref="imgUpload" :file-list="imgfileList" action="" :multiple="false" list-type="picture-card" :http-request="handBefore">
                  <!-- <i class="el-icon-plus"></i> -->
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span class="el-upload-list__item-delete" @click="handleRemoveImg(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="personBox">
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="车辆管理人" :label-width="labelWidth" prop="auto_manager">
                <el-input v-model="dform.auto_manager" placeholder="请输入车辆管理人">
                  <i slot="suffix" class="iconfont icon-yuan1" />
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式" :label-width="labelWidth" prop="auto_manager_tel">
                <el-input v-model="dform.auto_manager_tel" placeholder="请输入联系方式">
                  <i slot="suffix" class="iconfont icon-yuan1" />
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="事故驾驶员" :label-width="labelWidth" prop="driver_name">
                <el-input v-model="dform.driver_name" placeholder="请输入事故驾驶员">
                  <i slot="suffix" class="iconfont icon-yuan1" />
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式" :label-width="labelWidth" prop="driver_tel">
                <el-input v-model="dform.driver_tel" placeholder="请输入联系方式">
                  <i slot="suffix" class="iconfont icon-yuan1" />
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="随车人员一" :label-width="labelWidth" prop="fellow_user_one">
                <el-input v-model="dform.fellow_user_one" placeholder="请输入随车人员一">
                  <i slot="suffix" class="iconfont icon-yuan1" />
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式" :label-width="labelWidth" prop="fellow_user_one_tel">
                <el-input v-model="dform.fellow_user_one_tel" placeholder="请输入联系方式">
                  <i slot="suffix" class="iconfont icon-yuan1" />
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="随车人员二" :label-width="labelWidth" prop="fellow_user_two">
                <el-input v-model="dform.fellow_user_two" placeholder="请输入随车人员二">
                  <i slot="suffix" class="iconfont icon-yuan1" />
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系方式" :label-width="labelWidth" prop="fellow_user_two_tel">
                <el-input v-model="dform.fellow_user_two_tel" placeholder="请输入联系方式">
                  <i slot="suffix" class="iconfont icon-yuan1" />
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="上传情况说明书(责任人签字)" :label-width="labelWidth" prop="user_submit_file">
                <el-upload ref="uploadConfirm" :limit="1" :file-list="confirmfileList" action="" :multiple="false" list-type="picture-card" :http-request="handleConfirm">
                  <!-- <i class="el-icon-plus"></i> -->
                  <i slot="default" class="el-icon-plus"></i>
                  <div slot="file" slot-scope="{ file }">
                    <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
                    <span class="el-upload-list__item-actions">
                      <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                        <i class="el-icon-zoom-in"></i>
                      </span>
                      <span class="el-upload-list__item-delete" @click="handleRemoveConfirm(file)">
                        <i class="el-icon-delete"></i>
                      </span>
                    </span>
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelfun()">取 消</el-button>
        <!-- <el-button type="primary" @keyup.enter.native="addgoto()" @click="addgoto(tantype)">确 定</el-button> -->
        <el-button type="primary" @click="beforeSub">确 定</el-button>
      </span>
    </el-dialog>
    <!-- </div> -->
    <el-dialog :visible.sync="dialogVisibleImg">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
// import { AddImg } from '@/api/personnel'
import { Upimg } from '@/api/upimg'
import { GetautoList, addLosses, editLosses } from '@/api/car'
import { getList as getCarDetail } from '@/api/carindetail'

export default {
  name: 'dia',
  props: {
    dialogVisible: Boolean,
    isAddorEdit: String,
    infoObj: Object
  },
  data() {
    return {
      labelWidth: '120px',
      multipleSelection: [],
      loading: false,
      dialogVisibleImg: false,
      dialogImageUrl: '',
      fileList: [],
      // imgfileList: [
      //   { url: 'https://picd.zhimg.com/80/v2-d584b509e3b74c0d53834289132d38e7_720w.webp?source=1940ef5c' },
      //   { url: 'https://picd.zhimg.com/80/v2-6d5c0a28f676e9af1856b46a60fe0b67_720w.webp?source=1940ef5c' }
      // ],
      imgfileList: [],
      confirmfileList: [],
      tableData: [],
      carOptions: [],
      dutyOptions: [
        {
          label: '全责',
          value: 1
        },
        {
          label: '次责',
          value: 2
        },
        {
          label: '对等责任',
          value: 3
        },
        {
          label: '无责任',
          value: 4
        }
      ],
      cform: {
        cartype: '',
        project: '',
        metters: ''
      },
      dform: {
        auto_id: '', //车辆id
        ins_ids: [], //保险id
        ins_money: [], //金额
        happen_time: '', //事故时间
        losses_type: '', //责任占比：1-全责，2-次责，3-对等责任，4-无责任
        happen_addr: '', //发生地点
        is_response_file: 1, //是否有责任认定书
        response_file: [], //责任认定书
        response_pic: [],
        // response_pic: [
        //   'https://picd.zhimg.com/80/v2-d584b509e3b74c0d53834289132d38e7_720w.webp?source=1940ef5c',
        //   'https://picd.zhimg.com/80/v2-6d5c0a28f676e9af1856b46a60fe0b67_720w.webp?source=1940ef5c'
        // ], //事故现场照片
        reason: '', //事故原因
        auto_manager: '', // 管理人
        auto_manager_tel: '', //管理人电话
        driver_name: '', // 驾驶员
        driver_tel: '', // 驾驶员电话
        fellow_user_one: '', // 随车一
        fellow_user_one_tel: '', // 随车一电话
        fellow_user_two: '', // 随车二
        fellow_user_two_tel: '', // 随车二电话
        user_submit_file: [], // 用户确认文件
        is_auto_manager_assessment: 0,
        is_driver_assessment: 0,
        is_fellow_user_one_assessment: 0,
        is_fellow_user_two_assessment: 0
      },
      // 验证规则
      drules: {
        auto_id: [{ required: true, message: '请选择车辆', trigger: 'change' }],
        happen_time: [{ required: true, message: '请选择时间', trigger: 'change' }],
        happen_addr: [{ required: true, message: '请输入地点', trigger: 'blur' }],
        losses_type: [{ required: true, message: '请选择占比', trigger: 'change' }],
        reason: [{ required: true, message: '请输入详情', trigger: 'blur' }],
        auto_manager: [{ required: true, message: '请输入管理人', trigger: 'blur' }],
        auto_manager_tel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: '请输入正确的手机号码或者座机号'
          }
        ],
        driver_name: [{ required: true, message: '请输入驾驶员', trigger: 'blur' }],
        driver_tel: [
          { required: true, message: '请输入联系方式', trigger: 'blur' },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: '请输入正确的手机号码或者座机号'
          }
        ],
        response_file: [{ required: true, message: '请上传事故判定书', trigger: 'change' }],
        response_pic: [{ required: true, message: '请上传事故现场照片', trigger: 'change' }],
        user_submit_file: [{ required: true, message: '请上传情况说明书', trigger: 'change' }],
        // fellow_user_one: [{ required: true, message: '请输入随车人员', trigger: 'blur' }],
        fellow_user_one_tel: [
          { required: false, message: '请输入联系方式', trigger: 'blur' },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: '请输入正确的手机号码或者座机号'
          }
        ],
        // fellow_user_two: [{ required: true, message: '请输入随车人员', trigger: 'blur' }],
        fellow_user_two_tel: [
          { required: false, message: '请输入联系方式', trigger: 'blur' },
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: '请输入正确的手机号码或者座机号'
          }
        ]
      }
    }
  },
  async mounted() {
    await this.getallfun('')
  },
  methods: {
    selected(row, index) {
      // console.log(row.valid_status == 1)
      return row.valid_status == 1
    },
    // 金额两位小数正数
    inputCheck(value) {
      return value
        .replace(/^0[0-9]+/, val => val[1])
        .replace(/^(\.)+/, '')
        .replace(/[^\d.]/g, '')
        .replace(/\.+/, '.')
        .replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
    },
    // 是否有判定书，否清空
    changeIsFile(e) {
      console.log(e)
      if (e === 0) {
        this.fileList = []
        this.dform.response_file = []
      }
    },
    // 提交前处理
    async beforeSub() {
      if (this.multipleSelection.length !== 0) {
        this.dform.ins_ids = []
        this.dform.ins_money = []
        this.multipleSelection.map(item => {
          this.dform.ins_ids.push(item.ins_id)
          this.dform.ins_money.push(Number(item.ins_money) * 100)
        })
      } else {
        await this.$message.error('请选择险种')
      }
      // if (this.dform.is_response_file === 1 && this.dform.response_file.length === 0) {
      //   await this.$message.error('请上传事故判定书')
      // }
      // if (this.dform.response_pic.length === 0) {
      //   await this.$message.error('请上传事故现场照片')
      // }
      // if (this.dform.user_submit_file.length === 0) {
      //   await this.$message.error('请上传情况说明书(责任人签字)')
      // }
      console.log(this.dform)
      this.$refs['dform'].validate(async v => {
        if (v && this.multipleSelection.length !== 0) {
          // && this.dform.response_pic.length !== 0 && this.dform.user_submit_file.length !== 0
          // if (this.dform.is_response_file === 1 && this.dform.response_file.length === 0) {
          //   // alert(3)
          //   return
          // }
          let subObj = JSON.parse(JSON.stringify(this.dform))
          if (this.isAddorEdit === 'add') {
            // alert(1)
            const { data } = await addLosses(subObj)
            if (data.code !== 200) {
              return this.$message.error('添加失败')
            } else {
              this.$message.success('添加成功')
            }
          } else if (this.isAddorEdit === 'edit') {
            // alert(2)
            const { data } = await editLosses(subObj)
            if (data.code !== 200) {
              return this.$message.error('修改失败')
            } else {
              this.$message.success('修改成功')
            }
          }
          this.$emit('update')
          this.handleClose()
        }
      })
    },
    clearCform() {
      this.cform = {
        cartype: '',
        project: '',
        metters: ''
      }
      this.tableData = []
    },
    clearAll() {
      this.cform = {
        cartype: '',
        project: '',
        metters: ''
      }
      this.tableData = []
      this.dform = {
        auto_id: '', //车辆id
        ins_ids: [], //保险id
        ins_money: [], //金额
        happen_time: '', //事故时间
        losses_type: '', //责任占比：1-全责，2-次责，3-对等责任，4-无责任
        happen_addr: '', //发生地点
        is_response_file: 1, //是否有责任认定书
        response_file: [], //责任认定书
        response_pic: [],
        reason: '', //事故原因
        auto_manager: '', // 管理人
        auto_manager_tel: '', //管理人电话
        driver_name: '', // 驾驶员
        driver_tel: '', // 驾驶员电话
        fellow_user_one: '', // 随车一
        fellow_user_one_tel: '', // 随车一电话
        fellow_user_two: '', // 随车二
        fellow_user_two_tel: '', // 随车二电话
        user_submit_file: [], // 用户确认文件
        is_auto_manager_assessment: 0,
        is_driver_assessment: 0,
        is_fellow_user_one_assessment: 0,
        is_fellow_user_two_assessment: 0
      }
      this.fileList = []
      this.imgfileList = []
      this.confirmfileList = []
    },
    async setpreidfun(e) {
      console.log(e)
      const { data } = await getCarDetail({ id: e })
      if (data.code !== 200) {
        return this.$message.error('获取失败')
      }
      let datas = data.data
      this.clearCform()
      // if (this.isAddorEdit === 'add') {
      this.cform.project = datas.get_current_org.node_name
      // this.cform.cartype = datas.get_use_type === null ? '无' : datas.get_use_type.tname
      // this.cform.metters = datas.last_refueling.length === 0 ? '无' : datas.last_refueling.kilometre
      this.cform.cartype = datas.get_use_type.tname
      this.cform.metters = datas.last_refueling.kilometre
      let arr = datas.insurances
      arr.map(item => {
        item.ins_money = 0
        item.disabled = true
      })
      this.tableData = JSON.parse(JSON.stringify(arr))
      // }
    },
    // 远程搜索下拉合同
    async remoteMethod(query) {
      if (query !== '') {
        this.loading = true
        await this.getallfun(query)
      } else {
        this.carOptions = []
      }
    },
    async getallfun(key) {
      // 获取车辆
      const { data } = await GetautoList({ key: key, list_type: 'all', type: 1 })
      this.carOptions = data.data.list
      this.loading = false
      // console.log(this.carOptions, ' this.carOptions')
    },
    // // 判定树
    // async upimg1(porm) {
    //   let data = this.handBefore(porm).data
    //   console.log(data)
    //   await this.dform.response_pic.push(data.data.link)
    // },
    // // 现场照片
    // async upimg2(porm) {
    //   let data = this.handBefore(porm).data
    //   console.log(data)
    //   await this.dform.response_pic.push(data.data.link)
    // },
    // 通用上传
    // 签字书上传
    async handleConfirm(porm) {
      const fd = new FormData()
      fd.append('file[]', porm.file)
      const { data } = await Upimg(fd)
      // if (data.code !== 200) {
      //   return this.$message.error('上传失败')
      // }
      this.dform.user_submit_file.push(data[0].url)
      this.$refs.dform.validateField('user_submit_file')
      console.log(this.dform.user_submit_file, 'this.user_submit_file')
      console.log(this.confirmfileList, 'this.confirmfileList')
    },
    // 判定书上传
    async handleJudge(porm) {
      const fd = new FormData()
      fd.append('file[]', porm.file)
      const { data } = await Upimg(fd)
      console.log(data)
      // if (data.code !== 200) {
      //   return this.$message.error('上传失败')
      // }
      this.dform.response_file.push(data[0].url)
      this.$refs.dform.validateField('response_file')
      console.log(this.dform.response_file, 'this.response_file')
      console.log(this.fileList, 'this.imgfileList')
    },
    // 事故照片上传
    async handBefore(porm) {
      const fd = new FormData()
      fd.append('file[]', porm.file)
      const { data } = await Upimg(fd)
      // console.log(data)
      // if (data.code !== 200) {
      //   return this.$message.error('上传失败')
      // }
      this.dform.response_pic.push(data[0].url)
      this.$refs.dform.validateField('response_pic')
      console.log(this.dform.response_pic, 'this.response_pic')
      console.log(this.imgfileList, 'this.imgfileList')
      // return { data }
    },
    // 签字书------------------------------------------------------------删除
    handleRemoveConfirm(file, fileList) {
      console.log(file, 'fileelelelele')
      // 删除filelist
      let uploadFiles = this.$refs['uploadConfirm'].uploadFiles
      let index = uploadFiles.indexOf(file)
      uploadFiles.splice(index, 1)
      this.dform.user_submit_file.splice(index, 1)
      console.log(this.dform.user_submit_file, 'this.user_submit_file')
      console.log(this.confirmfileList, 'this.confirmfileList')
    },
    // 判定书----------------------------------------------------------------删除
    handleRemoveJudge(file, fileList) {
      console.log(file, 'fileelelelele')
      // 删除filelist
      let uploadFiles = this.$refs['uploadJudge'].uploadFiles
      let index = uploadFiles.indexOf(file)
      uploadFiles.splice(index, 1)
      this.dform.response_file.splice(index, 1)
      console.log(this.dform.response_file, 'this.response_file')
      console.log(this.fileList, 'this.imgfileList')
    },
    // 事故照片-------------------------------------------------------------删除
    handleRemoveImg(file, fileList) {
      console.log(file, 'fileelelelele')
      // 删除filelist
      let uploadFiles = this.$refs['imgUpload'].uploadFiles
      let index = uploadFiles.indexOf(file)
      uploadFiles.splice(index, 1)
      // 删除数组
      // const filePath = file.name
      // const i = this.dform.response_pic.findIndex(x => x === filePath)
      // this.dform.response_pic.splice(i, 1)
      this.dform.response_pic.splice(index, 1)
      // console.log(file, 'filefilefilefile')
      console.log(this.imgfileList, 'this.imgfileList')
      console.log(this.dform.response_pic, 'this.response_pic')
    },
    // -------------------------------------------------------------------------------
    handlePictureCardPreview(file) {
      console.log(file, 'file')
      this.dialogImageUrl = file.url
      this.dialogVisibleImg = true
    },
    // handleRemove(file, fileList) {
    //   console.log(file, fileList)
    // },
    handleSelectionChange(val, row) {
      this.multipleSelection = val
      console.log(val, 'val')
      console.log(row, 'row')
      this.tableData.map(item => {
        if (item.ins_id === row.ins_id) {
          item.disabled = false
        }
      })
      console.log(this.multipleSelection, 'this.multipleSelection')
    },
    // 取消
    cancelfun() {
      this.handleClose()
    },
    // 关闭弹窗
    handleClose() {
      this.$emit('handleClose')
      this.clearAll()
    }
  },
  watch: {
    dialogVisible: {
      handler(newV, oldV) {
        console.log(newV, 'dialogVisible')
      },
      deep: true,
      immediate: true
    },
    isAddorEdit: {
      handler(newV, oldV) {
        console.log(newV, 'isAddorEdit')
      },
      deep: true,
      immediate: true
    },
    infoObj: {
      async handler(newV, oldV) {
        console.log(newV, 'infoObj')
        let newObj = JSON.parse(JSON.stringify(newV))

        this.dform.id = newObj.id
        this.dform.auto_id = newObj.auto_id
        await this.setpreidfun(newObj.auto_id)
        console.log(this.tableData, 'this.tableData')
        console.log(newObj.get_ins_company, 'newObj.get_ins_company')
        // 循环对比赋值勾选
        let indexArr = []
        for (let i = 0; i < this.tableData.length; i++) {
          // 判断扫码得到的信息等于货物信息里面的信息
          if (newObj.get_ins_company.length !== 0) {
            // let pos = -1
            for (let j = 0; j < newObj.get_ins_company.length; j++) {
              if (newObj.get_ins_company[j].ins_id !== this.tableData[i].ins_id) {
                continue
              }
              this.tableData[i].ins_money = Number(newObj.get_ins_company[j].ins_money) / 100
              // pos = i
              indexArr.push(i)
            }
          } else {
            continue
          }
        }
        console.log(indexArr, 'indexArr')
        // console.log([this.tableData[0], this.tableData[1]])
        if (indexArr.length !== 0) {
          indexArr.forEach(item => {
            this.$refs.multipleTable.toggleRowSelection(this.tableData[item])
            this.tableData[item].disabled = false
          })
        } else {
          this.$refs.multipleTable.clearSelection()
        }
        this.dform.happen_time = newObj.happen_time
        this.dform.happen_addr = newObj.happen_addr
        this.dform.losses_type = newObj.losses_type
        this.dform.is_response_file = newObj.is_response_file
        this.dform.reason = newObj.reason
        newObj.response_file.map(item => {
          this.fileList.push({ url: item })
        })
        this.dform.response_file = newObj.response_file
        newObj.response_pic.map(item => {
          this.imgfileList.push({ url: item })
        })
        this.dform.response_pic = newObj.response_pic
        newObj.user_submit_file.map(item => {
          this.confirmfileList.push({ url: item })
        })
        this.dform.user_submit_file = newObj.user_submit_file
        this.dform.driver_name = newObj.get_losses_users[0].user_name
        this.dform.driver_tel = newObj.get_losses_users[0].user_tel
        this.dform.auto_manager = newObj.get_losses_users[1].user_name
        this.dform.auto_manager_tel = newObj.get_losses_users[1].user_tel
        if (newObj.get_losses_users[2]) {
          this.dform.fellow_user_one = newObj.get_losses_users[2].user_name
          this.dform.fellow_user_one_tel = newObj.get_losses_users[2].user_tel
        }
        if (newObj.get_losses_users[3]) {
          this.dform.fellow_user_two = newObj.get_losses_users[3].user_name
          this.dform.fellow_user_two_tel = newObj.get_losses_users[3].user_tel
        }
      },
      deep: true
      // immediate: true
    }
  }
}
</script>
<style scoped lang="less">
.dialogs {
  /deep/ .el-date-editor {
    width: 100% !important;
  }
  /deep/ .el-select {
    width: 100% !important;
  }
  /deep/ .el-input__inner {
    width: 100% !important;
  }
  .insureTypeBox {
    // display: flex;
    // justify-content: space-between;
    // align-items: flex-end;
    width: 95%;
    margin: 0 auto;
  }
  .detailBox {
    margin-top: 50px;
  }
  .personBox {
  }
}
</style>
